$(document).ready(function() {
    // $('.videoblockalt1__holder__play, .videoblock1__holder__play, .videoblock2__holder__play, .videoblock3__holder__play').on('click',  function () {
    //     let videoUrl = $(this).parent().attr('data-video');
    //
    //     if (!$(this).siblings('video').children('source').attr('src')) {
    //         $(this).siblings('video').get(0).load();
    //         $(this).siblings('video').children('source').attr('src', videoUrl);
    //     }
    //
    //     $(this).siblings('video').get(0).play();
    //     $(this).siblings('img').fadeOut();
    //     $(this).fadeOut();
    // });
    //
    // $('.videoblockalt1__holder__video, .videoblock1__holder__video, .videoblock2__holder__video, .videoblock3__holder__video').on('click',  function () {
    //     $(this).siblings('img').fadeIn();
    //     $(this).siblings('button').fadeIn();
    //     $(this).get(0).pause();
    // });

    $('.apply__job__button').on('click',  function () {
        $('#quick-apply-form').removeClass('open');
        $('#toggle-apply-form').addClass('open');
    });

    $('.apply__quick__button').on('click',  function () {
        $('#toggle-apply-form').removeClass('open');
        $('#quick-apply-form').addClass('open');
    });

    //Check if element is in viewport
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('resize scroll', function() {
        if ($('.vacancy__links')[0] && $('.vacancy__links').isInViewport()) {
            $('.vacancy__fixed').fadeOut();
        } else {
            $('.vacancy__fixed').fadeIn();
        }
    });
});
